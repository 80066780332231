import React, { Fragment } from "react";

import Layout from "../components/layout";
import PasswordForgetForm from "../components/Account/PasswordForget";

const PasswordForgetPage = () => (
  <Fragment>
    <h1>Wachtwoord vergeten?</h1>
    <PasswordForgetForm />
  </Fragment>
);

export default ({ location }) => (
  <Layout location={location}>
    <PasswordForgetPage />
  </Layout>
);
